import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { confetti } from '@xxxlgroup/hydra-icons';
import {
  Button,
  Content,
  Icon,
  List,
  ListItem,
  Link as HydraLink,
  Loading,
  Modal,
} from '@xxxlgroup/hydra-ui-components';
import { noop } from '@xxxlgroup/hydra-utils/common';
import useRoutes from 'hooks/useRoutes';
import useMessage from 'components/Message/useMessage';
import CmsContent from 'components/CmsContent';
import ErrorBoundary from 'components/ErrorBoundary';
import WebshopLink from 'components/WebshopLink';

import styles from 'modules/customer-account/components/TermsAndConditionsModal/components/TermsAndConditionsInfo/TermsAndConditionsInfo.scss';

const getAnchorCmsPageMatch = (cmsPagePattern, anchor) => {
  const href = anchor.getAttribute('href');
  return matchPath(href, {
    path: cmsPagePattern,
    exact: true,
    strict: false,
  });
};

const TermsAndConditionsInfo = ({ onConfirm, onAbort }) => {
  const [
    startParagraph,
    bullet1,
    bullet2,
    bullet3,
    bullet4,
    endParagraph,
    confirmButton,
    abortButton,
  ] = useMessage([
    'account.termsAndConditionsModal.startParagraph',
    'account.termsAndConditionsModal.bullet1',
    'account.termsAndConditionsModal.bullet2',
    'account.termsAndConditionsModal.bullet3',
    'account.termsAndConditionsModal.bullet4',
    'account.termsAndConditionsModal.endParagraph',
    'account.termsAndConditionsModal.confirmButton',
    'account.termsAndConditionsModal.abortButton',
  ]);
  const [modalCmsPageCode, setModalCmsPageCode] = useState(null);
  const { cmsPage, customerAccount } = useRoutes();
  const contentRef = useRef();
  const modalRef = useRef();
  const myAccountHref = `/${customerAccount.name}`;
  const listItems = [bullet1, bullet2, bullet3, bullet4];
  const closeModal = () => setModalCmsPageCode(null);

  useEffect(() => {
    if (!contentRef.current) {
      return noop;
    }

    const { current: contentElm } = contentRef;
    const createHandler = (cmsPageCode) => (event) => {
      event.preventDefault();
      setModalCmsPageCode(cmsPageCode);
    };
    const anchorCmsCodeTuples = Array.from(contentElm.querySelectorAll('a'))
      .map((anchor) => [anchor, getAnchorCmsPageMatch(cmsPage, anchor)])
      .filter(([, match]) => !!match)
      .map(([anchor, match]) => {
        const {
          params: { cmsPageCode },
        } = match;
        return [anchor, cmsPageCode];
      });

    const removeListeners = anchorCmsCodeTuples.map(([anchor, cmsPageCode]) => {
      const handler = createHandler(cmsPageCode);
      anchor.addEventListener('click', handler);
      return () => anchor.removeEventListener('click', handler);
    });

    return () => removeListeners.forEach((listener) => listener());
  }, [cmsPage]);

  useEffect(() => {
    const modal = modalCmsPageCode && modalRef.current;
    if (!modal) {
      return noop;
    }
    const modalClick = (event) => {
      const { target: anchor } = event;
      const targetIsAnchor = anchor.matches('a');
      const match = targetIsAnchor && getAnchorCmsPageMatch(cmsPage, anchor);
      if (match) {
        event.preventDefault();
        const { url } = match;
        if (url.indexOf('#') > 0) {
          const hash = url.split('#').pop();
          anchor.setAttribute('href', `#${hash}`);
          anchor.click();
        }
      }
    };
    modal.addEventListener('click', modalClick);

    return () => modal.removeEventListener('click', modalClick);
  }, [cmsPage, modalCmsPageCode]);

  return (
    <>
      <Icon glyph={confetti} className={styles.icon} />
      <Content content={startParagraph} size="sm" />
      <List bulletPoints="plus">
        {listItems.map((bulletMessage) => (
          <ListItem key={bulletMessage}>
            <Content content={bulletMessage} size="inherit" />
          </ListItem>
        ))}
      </List>
      <Content content={endParagraph} forwardedRef={contentRef} size="sm" />
      <WebshopLink
        href={myAccountHref}
        className={styles.confirmButton}
        onClick={onConfirm}
        as={<Button />}
      >
        {confirmButton}
      </WebshopLink>
      <HydraLink className={styles.abortButton} onClick={onAbort} layout="block" theme="dark">
        {abortButton}
      </HydraLink>
      {modalCmsPageCode && (
        <Modal
          variant="fit"
          onClose={closeModal}
          ref={modalRef}
          className={styles.modal}
          wrapperClassName={styles.modalWrapper}
        >
          <div className={styles.modalLoading}>
            <Loading />
          </div>
          <ErrorBoundary withStatusCode>
            <CmsContent pageCode={modalCmsPageCode} className={styles.cmsContent} />
          </ErrorBoundary>
        </Modal>
      )}
    </>
  );
};

TermsAndConditionsInfo.propTypes = {
  /** Callback when the confirm button was clicked */
  onConfirm: PropTypes.func,
  /** Callback when the abort button was clicked */
  onAbort: PropTypes.func,
};

TermsAndConditionsInfo.defaultProps = {
  onConfirm: noop,
  onAbort: noop,
};

export default TermsAndConditionsInfo;
