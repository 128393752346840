import { gql } from '@apollo/client';

export const CURRENT_USER_TAC_QUERY = gql`
  query getCustomer {
    getCustomer {
      tac {
        created
      }
    }
  }
`;
