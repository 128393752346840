import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, useNotifications } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { getCurrentDateInISOExtended } from 'modules/customer-account/utils/dateExtended';
import TermsAndConditionsInfo from 'modules/customer-account/components/TermsAndConditionsModal/components/TermsAndConditionsInfo';
import { CURRENT_USER_TAC_QUERY } from 'modules/customer-account/components/TermsAndConditionsModal/TermsAndConditionsModal.query';
import { ACCEPT_TERMS_AND_CONDITIONS_MUTATION } from 'modules/customer-account/components/TermsAndConditionsModal/TermsAndConditionsModal.mutation';

import styles from 'modules/customer-account/components/TermsAndConditionsModal/TermsAndConditionsModal.scss';

const getTermsAndConditionsResponse = async (accountEnabled, isAnonymous, getCurrentUserTac) => {
  if (accountEnabled && !isAnonymous) {
    const [{ data, errors }, exception] = await getCurrentUserTac().then(
      (response) => [response, false],
      (error) => [{}, error],
    );
    const tacAccepted = !!data?.getCustomer?.tac;
    const unclearResponse = !!errors || exception;
    return [unclearResponse, tacAccepted];
  }
  return [false, true];
};

const useIsComponentMounted = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback(() => mounted.current, []);
};

const TermsAndConditionsModal = () => {
  const history = useHistory();
  const notifications = useNotifications();
  const isMounted = useIsComponentMounted();
  const { isAnonymous, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [acceptTAC] = useMutation(ACCEPT_TERMS_AND_CONDITIONS_MUTATION);
  const { refetch: getCurrentUserTac } = useQuery(CURRENT_USER_TAC_QUERY, {
    fetchPolicy: 'no-cache',
    skip: true,
  });
  const accountEnabled = useFeatureFlagsEnabled('poseidon.account.enabled');
  const [heading, declinedFeedback] = useMessage([
    'account.termsAndConditionsModal.heading',
    'account.termsAndConditionsModal.feedback.declined',
  ]);

  const onAbort = async () => {
    await logout();
    notifications('loud', declinedFeedback);
    setOpen(false);
  };

  const onConfirm = () => {
    setOpen(false);
    acceptTAC({
      variables: {
        created: getCurrentDateInISOExtended(),
      },
      fetchPolicy: 'no-cache',
    });
  };

  useEffect(() => {
    let isUnclearResponse = false;

    const checkTermsAndConditions = async () => {
      const [unclearResponse, tacAccepted] = await getTermsAndConditionsResponse(
        accountEnabled,
        isAnonymous,
        getCurrentUserTac,
      );
      if (!unclearResponse) {
        // eslint-disable-next-line no-use-before-define
        unlistenHistory();
        if (!tacAccepted && isMounted()) {
          setOpen(true);
        }
      }
      isUnclearResponse = unclearResponse;
    };

    const unlistenHistory = history.listen(() => {
      isUnclearResponse && checkTermsAndConditions();
    });

    checkTermsAndConditions();

    return unlistenHistory;
  }, [accountEnabled, isAnonymous, getCurrentUserTac, history, isMounted]);

  return open ? (
    <Modal
      heading={heading}
      onClose={onAbort}
      className={styles.modal}
      wrapperClassName={styles.modalWrapper}
    >
      <TermsAndConditionsInfo onConfirm={onConfirm} onAbort={onAbort} />
    </Modal>
  ) : null;
};

export default TermsAndConditionsModal;
